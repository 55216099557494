<template>
  <div id="graduate-info" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <div class="columns">
        <div class="column has-background-light">
          <button
            type="submit"
            class="button btn-120 is-primary is-capitalized is-pulled-right"
            @click="initializeRestore()"
          >
            Restore
          </button>
        </div>
      </div>
    </template>
    <!-- Delete -->
    <app-modal
      id="graduate-student"
      context="update"
      @update="restore"
      :show-modal.sync="isModalOpen"
    >
      <template slot="add"
        ><p>You want to restore this student?</p>
        <p>Proceed?</p>
      </template>
    </app-modal>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { crudNotification } from '../../assets/js/notification'

export default {
  name: 'GraduateInfo',
  data() {
    return {
      schoolId: null,
      pageTitle: 'Graduate Info',
    }
  },
  methods: {
    initializeRestore() {
      this.openModal()
    },
    restore() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation restoreGraduateQuery($id: Int!) {
              restoreGraduate(input: { id: $id }) {
                graduate {
                  id
                  termId
                  schoolClassId
                }
                errors
              }
            }
          `,
          variables: {
            id: parseInt(this.$route.params.id),
          },
        })
        .then((response) => {
          crudNotification(
            response.data.restoreGraduate.errors,
            'Successfully restored.'
          ).then(() => {
            this.closeModal()
            this.$router.push(
              `/school/${this.schoolId}/graduates_info/${response.data.restoreGraduate.graduate.termId}`
            )
          })
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'Graduates Info',
        route: `/school/${this.schoolId}/graduates_info/${this.$route.params.term_id}`,
      },
      {
        name: 'Graduates',
        route: `/school/${this.schoolId}/graduates`,
      },
    ])
  },
}
</script>
